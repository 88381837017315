<template>
  <v-app dark>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({

  })
}
</script>
<style>
</style>
